import styled, { css } from "styled-components";

export const FormWrapper = styled.form`
  margin-top: 1.75rem;
  &:focus,
  &:focus-visible,
  &:focus-within,
  &:hover,
  &:visited,
  &:active {
    outline: 0 !important;
    border: 0 none !important;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    box-shadow: none;
  }
`;

export const FormFieldset = styled("fieldset")`
  border: none;
  outline: 0;
  margin: 0;
  padding: 0;
  display: block;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FormLegend = styled("legend")(({ theme }) => {
  const { typography, colors, spacings } = theme;
  return css`
    color: ${colors.black};
    font-family: "speedee_cd", sans-serif;
    font-size: ${typography.t3};
    font-weight: 400;
    text-align: center;
    line-height: 1.25;
    margin-bottom: ${spacings.s};
  `;
});

export const FormError = styled.div(({ theme }) => {
  return css`
    color: ${theme.colors.gold};
    font-size: 12px;
    margin-top: 10px;
    display: block;
    max-width: 200px;
  `;
});

export const FormInput = styled.input(({ theme }) => {
  const { colors, spacings, typography } = theme;
  return css`
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      -webkit-text-fill-color: ${colors.altGrey};
      -webkit-box-shadow: 0 0 0 ${spacings.xxxl} ${colors.altGrey} inset !important;
    }

    display: block;
    width: 100%;
    box-shadow: none;
    background: ${colors.altGrey};
    border: 1px solid ${colors.altGrey};
    border-radius: 5px;
    max-width: 200px;
    margin: 0 auto;
    height: ${spacings.large};
    line-height: ${spacings.large};
    font-family: "speedee", sans-serif;
    font-size: ${typography.t5};
    color: ${colors.black};
    padding: 0 ${spacings.s};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;

    &:focus,
    &:focus-visible,
    &:focus-within,
    &:hover,
    &:visited,
    &:active {
      outline: none !important;
      border: 1px solid ${colors.altGrey} !important;
      -webkit-appearance: none;
      -webkit-tap-highlight-color: transparent;
      box-shadow: none;
    }

    &.error {
      border-color: ${colors.gold} !important;
    }
  `;
});

export const ButtonBox = styled.div(({ theme }) => {
  const { spacings } = theme;
  return css`
    text-align: center;

    button {
      margin-top: ${spacings.xxxl};
    }
  `;
});
