import React from "react";
import { useHistory } from "react-router-dom";

import Caption from "components/Caption";
import { PageIntro, GtmButton } from "components/shared";
import { content } from "../content";

import { Hat } from "assets/images";

const Intro = () => {
  const history = useHistory();

  const handleGoToPostcode = () => {
    history.push("/postcode");
  };
  return (
    <>
      <PageIntro
        title={content.intro.introHeadline}
        text={content.intro.introText}
      >
        <GtmButton
          onClick={handleGoToPostcode}
          pageName={"Intro"}
          text={content.intro.introButton}
          sourceURL={"postcode"}
        />
      </PageIntro>
      <Caption text={content.caption.text} stickerImg={Hat} />
    </>
  );
};

export default Intro;
