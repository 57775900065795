import { useState, useEffect } from "react";

const useImages = (assets) => {
  const [isImagesLoaded, setIsImagesLoaded] = useState(true);
  const [numImagesLoaded, setNumImagesLoaded] = useState(0);

  useEffect(() => {
    const onLoad = () => setNumImagesLoaded((state) => state + 1);

    const preloadImg = (url, callback) => {
      const img = new Image();
      img.src = url;
      img.onload = callback;
      window[url] = img;
    };

    Object.entries(assets).forEach(([, url]) => {
      preloadImg(url, onLoad);
    });
  }, [assets]);

  useEffect(() => {
    if (
      Object.keys(assets).length !== 0 &&
      Object.keys(assets).length === numImagesLoaded
    ) {
      setIsImagesLoaded(false);
    }
  }, [numImagesLoaded, assets]);

  return isImagesLoaded;
};

export default useImages;
