import React, { useState, useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Form from "./Form";
import { Loader, Intro, Footer } from "components/shared";

import { useTimeout } from "hooks";

import { content } from "./content";
import { updateCafesData, saveCafesDataToStorage } from "./helpers";
import {
  getPostcodeByLatLng,
  getRestaurantsByPostCode,
} from "services/cloud.service";

import { PostCodeContext } from "context";

import { LoaderContainer } from "./Postcode.styles";

const Postcode = ({ userLatitude, userLongitude }) => {
  const [isLoading, setLoading] = useState(false);
  const [isPassedForm, setIsPassedForm] = useState({
    passed: true,
  });
  const history = useHistory();
  const setPostCodeContext = useContext(PostCodeContext)[1];
  const [, , setValidFlag] = useTimeout(() => {
    const defaultState = {
      passed: true,
    };
    setIsPassedForm(defaultState);
  }, 10000);

  const handleFormSubmit = useCallback(
    async ({ code }) => {
      try {
        setLoading(true);
        const data = await getRestaurantsByPostCode({
          postcode: code,
        });
        const restaurants = updateCafesData(data);
        saveCafesDataToStorage(restaurants);
        setLoading(false);

        if (restaurants) {
          if (restaurants.length > 0) {
            setPostCodeContext((oldState) => ({
              ...oldState,
              restaurants: restaurants,
            }));
            history.push("/restaurants");
          } else {
            const emptyData = {
              passed: false,
            };
            setIsPassedForm(emptyData);
          }
        }
      } catch (error) {
        const emptyData = {
          passed: false,
          error: error,
        };
        setIsPassedForm(emptyData);
        setLoading(false);
        console.error(error);
      }
    },
    [history, setPostCodeContext]
  );

  const getPostcodeByLocation = async () => {
    const postcodeByLocation = await getPostcodeByLatLng({
      lat: userLatitude,
      lng: userLongitude,
    });

    console.info("location", postcodeByLocation);
    return postcodeByLocation;
  };

  useEffect(() => {
    if (!isPassedForm.passed) {
      setValidFlag();
    }
  }, [isPassedForm, setValidFlag]);

  return (
    <>
      <Intro intro={content.intro} />
      <Form
        onSetPostcodeByLatLng={getPostcodeByLocation}
        lat={userLatitude}
        lng={userLongitude}
        onSubmit={handleFormSubmit}
        isPassedForm={isPassedForm}
      />
      <LoaderContainer>{isLoading && <Loader />}</LoaderContainer>
      <Footer />
    </>
  );
};

Postcode.propTypes = {
  userLatitude: PropTypes.number,
  userLongitude: PropTypes.number,
};

Postcode.defaultProps = {
  userLatitude: null,
  userLongitude: null,
};

export default Postcode;
