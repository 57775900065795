const isValidUrl = (str) => {
  if (typeof str !== "string") {
    return false;
  }
  return str.indexOf("http://") > -1 || str.indexOf("https://") > -1;
};

const saveCafesDataToStorage = (cafeList) => {
  sessionStorage.setItem("mcd-cafes", JSON.stringify(cafeList));
};

const updateCafesData = (restaurants) => {
  return restaurants.map((item) => {
    return isValidUrl(item.url) ? item : { ...item, invalidUrl: true };
  });
};

export { updateCafesData, saveCafesDataToStorage };
