import React, { useState, useContext, useEffect } from "react";

import { GtmButton, Intro, Footer } from "components/shared";
import { content } from "./content";

import { PostCodeContext } from "context";

import { CafesContainer } from "./Restaurants.styles";

const Restaurants = () => {
  const [{ restaurants }] = useContext(PostCodeContext);
  const [cafeList, setCafeList] = useState(restaurants);

  useEffect(() => {
    if (restaurants.length < 1) {
      const cachesData = sessionStorage.getItem("mcd-cafes");
      if (cachesData) {
        const cachedCafes = JSON.parse(cachesData);
        setCafeList(cachedCafes);
      }
    }
  }, [restaurants]);

  return (
    <>
      <Intro intro={content.intro} />
      {cafeList.length > 0 && (
        <CafesContainer>
          {cafeList.map((item, key) => {
            return (
              <li key={"item-" + key}>
                <GtmButton
                  as="a"
                  secondary
                  href={
                    !item.invalidUrl
                      ? item.url
                      : "mailto:" +
                        item.email +
                        "?subject=Kinderfeestje&body=" +
                        content.mail.body
                  }
                  className="button"
                  text={item.name}
                  pageName={"Restaurants"}
                >
                  {item.name}
                </GtmButton>
              </li>
            );
          })}
        </CafesContainer>
      )}
      <Footer />
    </>
  );
};

export default Restaurants;
