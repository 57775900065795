export const content = {
  intro: {
    introHeadline: "Maak een keuze uit één van de restaurants.",
    introText: `Dit zijn de restaurants, binnen 25 km, die bij jou in de buurt 
    kinderfeestjes aanbieden. Kies er één waar jij graag een feestje wilt bouwen 🎉`,
  },
  mail: {
    body:
      "Beste McDonald's restaurant,%0D%0A%0D%0A Een kinderfeestje bij de McDonald’s, " +
      "wat onwijs leuk!Graag zou ik mijn " +
      "kinderfeestje bij jullie willen organiseren.%0D%0AHet zal gaan om X(aantal) kinderen op " +
      "X(datum).%0D%0AGraag hoor ik of dit mogelijk is. Bovendien zou ik graag meer informatie van jullie " +
      "willen ontvangen. Wij hebben er al heel veel zin in!%0D%0AMet vriendelijke groet,",
  },
};
