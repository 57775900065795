import React, { useEffect, useRef } from "react";

import lottieService from "services/lottie.service";

import { FooterWrapper, FooterContent } from "./Footer.styles";

const Footer = () => {
  const lottieFooterRef = useRef(null);

  useEffect(() => {
    lottieService({
      container: lottieFooterRef.current,
      animationData: require("lotties/postcode.json"),
    });
  }, []);

  return (
    <FooterWrapper>
      <FooterContent ref={lottieFooterRef} />
    </FooterWrapper>
  );
};

export default Footer;
