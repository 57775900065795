import styled, { css } from "styled-components";

export const Intro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: ${(props) => props.blockWidth || "300px"};
`;

export const Content = styled.div(({ theme }) => {
  const { spacings } = theme;
  return css`
    position: relative;
    width: 100%;
    margin-bottom: ${spacings.xxxl}; ;
  `;
});

export const IntroTitle = styled.h2(({ theme }) => {
  const { colors, spacings, typography } = theme;

  return css`
    font-size: ${typography.t3};
    font-weight: bold;
    color: ${colors.black};
    text-align: center;
    padding: 0 ${spacings.s};
    user-select: none;
  `;
});

export const IntroText = styled.p(({ theme }) => {
  const { colors, typography } = theme;

  return css`
    font-size: ${typography.t5};
    text-align: center;
    color: ${colors.black};
    max-width: 235px;
    padding: 0;
    margin: 0 auto;
    user-select: none;
  `;
});
