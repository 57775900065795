import React from "react";
import PropTypes from "prop-types";

import {
  CaptionWrapper,
  CaptionContent,
  Text,
  Sticker,
} from "./Caption.styles";

function Caption({ text, stickerImg, ...rest }) {
  const isHasContent = stickerImg || text;
  return (
    <CaptionWrapper {...rest}>
      {isHasContent && (
        <CaptionContent>
          {text && <Text dangerouslySetInnerHTML={{ __html: text }} />}
          {stickerImg && <Sticker src={stickerImg} alt={text} />}
        </CaptionContent>
      )}
    </CaptionWrapper>
  );
}

Caption.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  stickerImg: PropTypes.node,
};

Caption.defaultProps = {
  text: "",
  stickerImg: null,
};

export default Caption;
