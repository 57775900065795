import React from "react";

import Header from "./Header";
import Intro from "./Intro";

const Landing = () => {
  return (
    <>
      <Header />
      <Intro />
    </>
  );
};

export default Landing;
