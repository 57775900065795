import styled, { createGlobalStyle, css } from "styled-components";

export default createGlobalStyle(({ theme }) => {
  const { colors, typography, spacings } = theme;
  return css`
    * {
      box-sizing: border-box;
    }

    html,
    body {
      margin: 0;
      padding: 0;
      -ms-overflow-style: none;
      width: 100%;
      min-height: 100%;
      -webkit-text-size-adjust: 100%;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      overflow-x: hidden;
      -webkit-touch-callout: none;
    }

    body {
      font-family: "speedee", sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: ${colors.black};
      font-size: ${typography.t5};
      line-height: 1.5;
      background: ${colors.lightGrey};
    }

    #root {
      height: 100%;
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }

    h1,
    h2,
    h3 {
      font-family: "speedee_cd", sans-serif;
      margin: 0 0 ${spacings.l};
      line-height: 1.25;
    }

    h1 {
      font-size: ${typography.t1};
    }

    h2 {
      font-size: ${typography.t3};
      margin: 0 0 ${spacings.base};
    }

    h3 {
      font-size: ${typography.t4};
      margin-bottom: ${spacings.s};
    }

    p {
      margin: 0 0 ${spacings.l};
      font-size: ${typography.t5};
      line-height: 1.25;
      text-align: center;
      color: ${colors.black};
      padding: 0 ${spacings.s};
      user-select: none;
    }

    a {
      &:active,
      &:hover {
        outline: 0;
      }
    }

    button {
      border: 0;
      cursor: pointer;
    }

    img {
      max-width: 100%;
      vertical-align: top;
    }

    svg {
      vertical-align: top;
    }

    .pt-4 {
      padding-top: 4.5rem;

      p {
        max-width: 100%;
      }
    }
  `;
});

export const AppWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const AppLayout = styled.div(({ theme }) => {
  const { spacings } = theme;

  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    justify-content: start;
    max-width: 375px;
    padding: ${spacings.l};
    margin: 0 auto;
  `;
});
