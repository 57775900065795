import styled, { css } from "styled-components";

export const CaptionWrapper = styled.div(({ theme }) => {
  const { spacings } = theme;

  return css`
    padding-bottom: ${spacings.base};
  `;
});

export const CaptionContent = styled.div(({ theme }) => {
  const { colors, spacings } = theme;

  return css`
    position: relative;
    width: 100%;
    max-width: 312px;
    margin: ${spacings.xxxl} auto 0;
    padding: ${spacings.base} ${spacings.s};
    background: ${colors.white};
    border: 1px solid ${colors.gold};
    border-radius: 5px;
  `;
});

export const Sticker = styled.img`
  display: block;
  position: absolute;
  text-align: center;
  margin: 0 auto;
  bottom: -22px;
  right: -18px;
  height: auto;
  z-index: 2;
`;

export const Text = styled.p`
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  line-height: 1.5;
`;
