import { useEffect, useState } from "react";
import FontFaceObserver from "fontfaceobserver";

const useFonts = () => {
  const [isFontsLoading, setIsFontsLoading] = useState(false);

  useEffect(() => {
    const speedee_bold = new FontFaceObserver("speedee", { weight: 600 });
    const speedee_reg = new FontFaceObserver("speedee", { weight: 400 });

    Promise.all([speedee_bold.load(), speedee_reg.load()]).then(
      () => setIsFontsLoading(true),
      () => setIsFontsLoading(false)
    );
  });

  return isFontsLoading;
};

export default useFonts;
