import styled, { css } from "styled-components";

export const ButtonBase = styled.button(({ theme }) => {
  const { typography, colors, spacings } = theme;

  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    color: ${colors.black};
    font-size: ${typography.t5};
    font-weight: bold;
    line-height: 1.1;
    cursor: pointer;
    text-align: center;
    user-select: none;
    z-index: 1;
    text-decoration: none;
    border-radius: 29px;
    box-shadow: 0 3px 6px rgb(112, 112, 112, 0.2);
    min-width: 200px;
    margin: 0.35rem auto 0;
    padding: ${spacings.m1} ${spacings.s};
    transition: background-color 0.3s ease-out;

    &:disabled,
    &.disabled {
      pointer-events: none;
      opacity: 0.85;
    }

    &:hover,
    &:active {
      transition: background-color 0.3s ease-out;
    }
  `;
});

export const ButtonPrimary = styled(ButtonBase)(({ theme }) => {
  const { colors } = theme;

  return css`
    background-color: ${colors.gold};
    min-width: 200px;

    &:hover,
    &:active {
      background-color: ${colors.white};
    }
  `;
});

export const ButtonSecondary = styled(ButtonBase)(({ theme }) => {
  const { colors } = theme;

  return css`
    background-color: ${colors.white};
    width: 100%;

    &:focus,
    &:active {
      background-color: ${colors.gold};
    }
  `;
});
