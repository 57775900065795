import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";

import { useFonts, useImages, useLoader, usePosition } from "hooks";

import { postCodeContextDefValue, PostCodeContext } from "context";

import { Landing, Postcode, Restaurants } from "routes";

import { AppWrapper, AppLayout } from "theme/globalStyles";

import * as images from "assets/images";

function App() {
  const [postCodeContext, setPostCodeContext] = useState(
    postCodeContextDefValue
  );
  const { latitude, longitude } = usePosition();
  const isFontsLoading = useFonts();
  const isImagesLoaded = useImages(images);
  const loader = useLoader(!isFontsLoading || isImagesLoaded);

  return (
    <PostCodeContext.Provider value={[postCodeContext, setPostCodeContext]}>
      <AppWrapper>
        <AppLayout>
          {loader || (
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route
                exact
                path="/postcode"
                render={() => (
                  <Postcode userLatitude={latitude} userLongitude={longitude} />
                )}
              />
              <Route exact path="/restaurants" component={Restaurants} />
              <Route path="*" component={() => <h1>404</h1>} />
            </Switch>
          )}
        </AppLayout>
      </AppWrapper>
    </PostCodeContext.Provider>
  );
}

export default App;
