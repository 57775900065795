export const content = {
  intro: {
    introHeadline: "Waar is dat feestje?",
    introText: `Vul je postcode in en kijk welke restaurants er bij jou in de buurt kinderfeestjes geven.`,
    introButton: "Volgende",
  },
  postcode: {
    text: "Postcode",
    fieldError: "Postcode niet bekend",
    fieldErrorNoRestaurants:
      "Er is binnen 25 km geen McDonald's die kinderfeestjes organiseert",
  },
};
