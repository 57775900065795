import styled, { css } from "styled-components";

import { ButtonSecondary } from "components/shared/Button/Button.styles";

export const CafesContainer = styled.ul(({ theme }) => {
  const { spacings } = theme;

  return css`
    padding: 0;
    list-style: none;
    width: 100%;
    max-width: 290px;
    margin: 0 auto;

    li {
      margin: 0 0 ${spacings.m2} 0;
    }

    ${ButtonSecondary} {
      text-align: left;
      line-height: normal;
      margin: 0;
      justify-content: flex-start;
      padding: ${spacings.m} ${spacings.xl};
    }
  `;
});
