export const content = {
  intro: {
    introHeadline: "Het leukste kinderfeestje, vier het bij McDonald’s!",
    introText: `Wat is er nou leuker dan een kinderfeestje vieren bij McDonald’s? Plan snel 
    een feestje en maak er een top dag van!`,
    introAdditionalText: [
      `Door de huidige Coronamaatregelingen bieden niet 
    alle restaurants meer kinderfeestjes aan. Check het dubbel door een mail te sturen naar 
    het desbetreffende restaurant.`,
    ],
    introButton: "Volgende",
  },
  caption: {
    text: `Wist je dat je bij sommige restaurants themafeestjes kan vieren?`,
  },
};
