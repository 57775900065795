const apiUrlStage =
  "https://europe-west1-mcd-nl-kinderfeestje-staging.cloudfunctions.net/";

const apiUrlProd =
  "https://europe-west1-mcd-nl-kinderfeestje-prod.cloudfunctions.net/";

let apiUrl = process.env.REACT_APP_ENV === "staging" ? apiUrlStage : apiUrlProd;

console.info(process.env.REACT_APP_ENV);

export const getRestaurantsByPostCode = async ({ postcode }) => {
  try {
    const result = await fetch(
      `${apiUrl}getRestaurantsByPostCode?postcode=${encodeURI(postcode)}`,
      { method: "GET" }
    );
    const data = await result.json();
    return data.restaurants;
  } catch (error) {
    throw new Error(error);
  }
};

export const getPostcodeByLatLng = async ({ lat, lng }) => {
  try {
    const result = await fetch(
      `${apiUrl}getPostcodeByLatLng?lat=${lat}&lng=${lng}`,
      { method: "GET" }
    );
    const data = await result.json();
    return data.postcode;
  } catch (error) {
    throw new Error(error);
  }
};
