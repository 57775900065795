// color palette
const primary = {
  white: "#FFFFFF",
  black: "#151515",
  grey: "#707070",
  altGrey: "#eaeaea",
  lightGrey: "#F7F7F7",
  gold: "#FFBC0D",
  red: "#DB0007",
  green: "#264F36",
  blue: "#56AFD1",
};

export const spacings = {
  largeSecond: "3.75rem", // 60px
  large: "3.125rem", // 50px
  xxxl: "3rem", // 48px
  xxl: "2rem", // 32px
  xl: "1.5625rem", // 25px
  l: "1.25rem", // 20px
  base: "1rem", // 16px
  m: "0.875rem", // 14px
  m1: "0.8125rem", // 13px
  m2: "0.75rem", // 12px
  s: "0.5rem", // 8px
  xs: "0.25rem", // 4px
};

export const typography = {
  t1: "2.25rem", // 36px
  t2: "1.875rem", // 30px
  t3: "1.5rem", // 24px
  t4: "1.125rem", // 18px
  t5: "1rem", // 16px
  t6: "0.875rem", // 14px
  t7: "0.75rem", // 12px
};

export const colors = {
  ...primary,
};
