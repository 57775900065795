import styled, { css } from "styled-components";

export const LoaderContainer = styled.div(() => {
  return css`
    div {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      z-index: 10;
    }
  `;
});
