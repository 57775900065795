import styled, { css } from "styled-components";

export const HeaderWrapper = styled.div(({ theme }) => {
  const { spacings } = theme;

  return css`
    position: relative;
    text-align: center;
    margin-top: -${spacings.l};
    margin-bottom: -${spacings.largeSecond};
    overflow-x: hidden;
  `;
});

export const HeaderContent = styled.div`
  width: 100vw;
`;
