import React, { useRef, useEffect } from "react";

import lottieService from "services/lottie.service";

import { LoaderContainer } from "./Loader.styles";

const Loader = () => {
  const lottieLoaderRef = useRef(null);

  useEffect(() => {
    lottieService({
      container: lottieLoaderRef.current,
      animationData: require("lotties/loader.json"),
    });
  }, []);

  return <LoaderContainer ref={lottieLoaderRef}></LoaderContainer>;
};

export default Loader;
