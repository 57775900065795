import styled, { css } from "styled-components";

export const LoaderContainer = styled.div(({ theme }) => {
  const { colors, spacings } = theme;

  return css`
    display: "flex";
    font-size: ${spacings.l};
    justify-content: "center";
    align-items: "center";
    color: ${colors.white};
    margin-top: auto;
    margin-bottom: auto;
  `;
});
