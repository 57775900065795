import React from "react";
import PropTypes from "prop-types";

import { ButtonPrimary, ButtonSecondary } from "./Button.styles";

const Button = ({ text, secondary, children, ...props }) =>
  secondary ? (
    <ButtonSecondary {...props}>{text || children}</ButtonSecondary>
  ) : (
    <ButtonPrimary {...props}>{text || children}</ButtonPrimary>
  );

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  className: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
};

Button.defaultProps = {
  children: null,
  className: null,
  text: "",
  disabled: false,
  secondary: false,
};

export default Button;
