import React, { useEffect, useRef } from "react";

import lottieService from "services/lottie.service";

import { HeaderWrapper, HeaderContent } from "./Header.styles";

const Header = () => {
  const lottieHeaderRef = useRef(null);

  useEffect(() => {
    lottieService({
      container: lottieHeaderRef.current,
      animationData: require("lotties/start.json"),
    });
  }, []);

  return (
    <HeaderWrapper>
      <HeaderContent ref={lottieHeaderRef} />
    </HeaderWrapper>
  );
};

export default Header;
