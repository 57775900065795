import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { GtmButton } from "components/shared";

import { useForm } from "react-hook-form";

import { content } from "../content";

import {
  FormWrapper,
  FormFieldset,
  FormLegend,
  FormInput,
  FormError,
  ButtonBox,
} from "./Form.styles";

const Form = ({ onSetPostcodeByLatLng, onSubmit, isPassedForm }) => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
  } = useForm();

  const addUserData = ({ postalCode }) => {
    onSubmit({ code: postalCode });
  };

  useEffect(() => {
    const handleSetPostcodeValue = () => {
      onSetPostcodeByLatLng().then((data) => {
        setValue("postalCode", data, {
          shouldValidate: true,
          shouldDirty: true,
        });
        console.info("Postcode data", data);
      });
    };
    return handleSetPostcodeValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isPassedForm.passed) {
      setError("postalCode", {
        type: isPassedForm.error ? "error" : "manual",
      });
    } else {
      clearErrors("postalCode");
    }
  }, [setError, clearErrors, isPassedForm]);

  return (
    <FormWrapper onSubmit={handleSubmit(addUserData)} autoComplete="off">
      <FormFieldset>
        <FormLegend>Postcode</FormLegend>
        <FormInput
          type="text"
          id="postalCode"
          name="postalCode"
          {...register("postalCode", {
            required: true,
            pattern: { value: /^[0-9]{4}\s?[a-zA-Z]{2}$/ },
          })}
          onChange={() => {
            clearErrors("postalCode");
          }}
          className={`${errors.postalCode ? "error" : ""}`}
        />
        {errors.postalCode && (
          <FormError>
            {errors.postalCode.type === "manual"
              ? content.postcode.fieldErrorNoRestaurants
              : content.postcode.fieldError}
          </FormError>
        )}
      </FormFieldset>
      <ButtonBox>
        <GtmButton
          type="submit"
          pageName={"PostCode"}
          text={content.intro.introButton}
          sourceURL={"restaurants"}
        />
      </ButtonBox>
    </FormWrapper>
  );
};

Form.propTypes = {
  onSetPostcodeByLatLng: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isPassedForm: PropTypes.object.isRequired,
};

export default Form;
