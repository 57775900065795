import React from "react";
import PropTypes from "prop-types";

import { PageIntro } from "components/shared";

import { WrapperIntro } from "./Intro.styles";

const Intro = ({ intro }) => {
  return (
    <WrapperIntro>
      <PageIntro
        className="pt-4"
        title={intro.introHeadline}
        text={intro.introText}
      />
    </WrapperIntro>
  );
};

Intro.propTypes = {
  intro: PropTypes.object,
};

Intro.defaultProps = {
  intro: {},
};

export default Intro;
