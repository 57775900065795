import styled, { css } from "styled-components";

export const FooterWrapper = styled.div(({ theme }) => {
  const { spacings } = theme;
  return css`
    position: relative;
    text-align: center;
    margin-left: -${spacings.l};
    margin-right: -${spacings.l};
    overflow-x: hidden;
    margin-top: auto;
  `;
});

export const FooterContent = styled.div`
  width: 100vw;
`;
