import React from "react";
import PropTypes from "prop-types";

import { Button } from "components/shared";

const GtmButton = ({ onClick, sourceURL, pageName, ...props }) => {
  const sendGtmData = () => {
    const { protocol, host, href } = window.location;
    const label =
      sourceURL.indexOf("http") > -1
        ? sourceURL
        : props.href !== ""
        ? props.href
        : protocol + "//" + host + "/" + sourceURL;

    dataLayer.push({
      event: "buttonClick",
      eventAction: `${props.text}`,
      eventLabel: `${label}`,
      eventProperties: {
        event: "page_view",
        page_type: "webview",
        page_template: "My Family Kinderfeestjes " + pageName,
        page_virtual_path: `${href}`,
      },
    });
    if (onClick) {
      onClick();
    }
  };

  return <Button {...props} onClick={sendGtmData} />;
};

GtmButton.propTypes = {
  onClick: PropTypes.func,
  pageName: PropTypes.string,
  sourceURL: PropTypes.string,
  text: PropTypes.string,
  href: PropTypes.string,
};

GtmButton.defaultProps = {
  onClick: () => {},
  pageName: "",
  sourceURL: "",
  text: "",
  href: "",
};

export default GtmButton;
