import React from "react";
import PropTypes from "prop-types";

import { Intro, Content, IntroTitle, IntroText } from "./PageIntro.styles";

function PageIntro({ title, text, additionalText, children, ...rest }) {
  const isHasContent = title || text;

  return (
    <Intro {...rest}>
      {isHasContent && (
        <Content>
          {title && <IntroTitle>{title}</IntroTitle>}
          {text && <IntroText dangerouslySetInnerHTML={{ __html: text }} />}
          {additionalText &&
            additionalText.map((item, key) => {
              return (
                <React.Fragment key={"item-" + key}>
                  <br />
                  <IntroText dangerouslySetInnerHTML={{ __html: item }} />
                </React.Fragment>
              );
            })}
        </Content>
      )}
      {children}
    </Intro>
  );
}

PageIntro.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  additionalText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  blockWidth: PropTypes.string,
};

PageIntro.defaultProps = {
  title: "",
  text: "",
  blockWidth: null,
  additionalText: "",
};

export default PageIntro;
